<ems-toast-message #msgToast></ems-toast-message>

<ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="(createUserForm.get('phoneNumber').value && createUserForm.get('phoneNumber').invalid) ||
(createUserForm.get('firstName').value && (createUserForm.get('firstName').invalid)) ||
(createUserForm.get('lastName').value && (createUserForm.get('lastName').invalid)) ||
(createUserForm.get('emailAddress').value && isEmailIdInvalid) ||
(isUserTypeInt && createUserForm.get('opaqueId').value && isOpaqueIdInvalid) || duplicateClientError">
  <div class="sdps-notification__text">
    <p [innerHTML]="getUsersConstant.manageUserPage.errorMsg.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li
        *ngIf="(createUserForm.get('firstName').value && (createUserForm.get('firstName').invalid)) || (createUserForm.get('lastName').value && (createUserForm.get('lastName').invalid))"
        [innerHTML]="getUsersConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue">
      </li>
      <li *ngIf="(isUserTypeInt && createUserForm.get('opaqueId').value && isOpaqueIdInvalid)"
        [innerHtml]="getUsersConstant.manageUserPage.invalidSchwabId">
      </li>
      <li *ngIf="(createUserForm.get('emailAddress').value && isEmailIdInvalid)"
          [innerHTML]="getUsersConstant.manageUserPage.invalidEmailAddress">
      </li>
      <li *ngIf="(createUserForm.get('phoneNumber').value && !isPhoneValid) ||
      (createUserForm.get('phoneNumber').value && createUserForm.get('phoneNumber').invalid)"
          [innerHTML]="getUsersConstant.manageUserPage.errorMsg.phoneNumbershouldBeNumeric">
      </li>
      <li *ngIf="duplicateClientError" [innerHTML]="duplicateClientErrorMsg">
      </li>
    </ul>
  </div>
</sdps-notification>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="clientAccess">
  <p class="sdps-notification__text">{{clientAccessMsg}}</p>
</sdps-notification>

<ems-confirm-message #confirmationMessage></ems-confirm-message>

<form [formGroup]="createUserForm">
  <!-- Page Header -->
  <h2 class="page-title sdps-m-bottom_large" [innerHTML]="getUsersConstant.manageUserPage.enterUserinfo"
      *ngIf="!userService.editMode"></h2>
  <h2 class="page-title sdps-m-bottom_large" [innerHTML]="getUsersConstant.manageUserPage.userDetails"
      *ngIf="userService.editMode && !isExternalUserDeActive"></h2>
  <h2 class="page-title sdps-m-bottom_large" [innerHTML]="getUsersConstant.manageUserPage.viewUserDetails"
      *ngIf="userService.editMode && isExternalUserDeActive"></h2>

  <div class="select-rule-type" [ngClass]="!userService.editMode ? 'step-border-box': ''">

    <div class="sdps-grid-container sdps-wrap no-margin">
      <div class="sdps-row sdps-m-top_small">
        <div class="select-rule-type"
             [ngClass]="userService.editMode? 'step-border-box col-8': 'col'">
          <!-- User Type Section -->
          <div *ngIf="!isExternalAdmin" class="sdps-p-around_medium">
            <strong class="sdps-m-top_small" [innerHTML]="getUsersConstant.manageUserPage.userType"></strong>
            <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>

            <!-- Internal -->
            <label class="sdps-m-left_small">
              <p-radioButton class="emtr-radio ems-inline" id="internalUserType" name="userType"
                             formControlName="userType" value="{{getUsersConstant.manageUserPage.internal}}"
                             label="{{getUsersConstant.manageUserPage.internal}}"></p-radioButton>
            </label>

            <!-- External -->
            <label class="sdps-m-left_small">
              <p-radioButton class="emtr-radio ems-inline" id="externalUserType" name="userType"
                             formControlName="userType"
                             value="{{getUsersConstant.manageUserPage.external}}"
                             label="{{getUsersConstant.manageUserPage.external}}"></p-radioButton>
            </label>

          </div>

          <div class="sdps-grid-container sdps-wrap no-margin">
            <div class="sdps-m-left_medium sdps-m-top_small" [ngClass]="userService.editMode? 'col' : 'col-7'">
              <!-- First and Last Name Section -->
              <div class="sdps-grid-fluid">
                <!-- First Name -->
                <div class="col-5 rule-type-textbox sdps-p-around_medium"
                     [ngClass]="{'invalidField': ((createUserForm.get('firstName').value && createUserForm.get('firstName').invalid)), 'disabled-grey': ((myaccessModeEnabled && createUserForm.get('userType').value === 'Internal') || isExternalUserDeActive)}">
                  <span class="p-float-label">
                    <input type="text" id="firstName" [maxlength]="30" formControlName="firstName" restrictValidation emsNamePatternCheck
                           emsCharacterLimit [charMaxLimit]="getCommonConstant.charLimitValues.thirtyMaxLimit"
                           (emsCharacterLimitCheck)="showCharLimit = $event" pInputText>
                    <label>
                      {{getUsersConstant.manageUserPage.firstName}}
                      <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </label>
                  </span>
                </div>

                <!-- Last Name -->
                <div class="col-5 rule-type-textbox sdps-p-around_medium sdps-m-left_large"
                     [ngClass]="{'invalidField': ((createUserForm.get('lastName').value && createUserForm.get('lastName').invalid)), 'disabled-grey': ((myaccessModeEnabled && createUserForm.get('userType').value === 'Internal') || isExternalUserDeActive)}">
                  <span class="p-float-label">
                    <input type="text" id="lastName" [maxlength]="30" restrictValidation emsNamePatternCheck formControlName="lastName"
                           emsCharacterLimit [charMaxLimit]="getCommonConstant.charLimitValues.thirtyMaxLimit"
                           (emsCharacterLimitCheck)="showCharLimit = $event" pInputText>
                    <label>
                      {{getUsersConstant.manageUserPage.lastName}}
                      <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </label>
                  </span>
                </div>
              </div>

              <!-- First and Last Name Char limit Section -->
              <div class="sdps-grid-fluid charCls"
                   *ngIf="createUserForm.get('firstName').value.length >= showCharLimit || createUserForm.get('lastName').value.length >= showCharLimit">
                <div class="col-5">
                  <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
                     *ngIf="createUserForm.get('firstName').value.length >= showCharLimit">
                    {{getEMTRConstant.rulesConstant.characterLimit}}
                    <span class="character-limit">{{createUserForm.get('firstName').value.length}}/{{getCommonConstant.charLimitValues.thirtyMaxLimit}}</span>
                  </p>
                </div>

                <div class="col-5 sdps-m-left_large">
                  <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-p-left_small sdps-text-small"
                     *ngIf="createUserForm.get('lastName').value.length >= showCharLimit">
                    {{getEMTRConstant.rulesConstant.characterLimit}}
                    <span class="character-limit">{{createUserForm.get('lastName').value.length}}/{{getCommonConstant.charLimitValues.thirtyMaxLimit}}</span>
                  </p>
                </div>
              </div>

              <!-- Country code and Phone Number Section -->
              <div class="sdps-grid-fluid">
                <!-- Country code -->
                <div class="col-5 sdps-p-around_medium sdps" [class.country-code-dropdown-disable]="((myaccessModeEnabled && createUserForm.get('userType').value === 'Internal') || isExternalUserDeActive)" [class.create-user]="!(myaccessModeEnabled && createUserForm.get('userType').value === 'Internal')">
                  <span class="p-float-label">
                   <p-dropdown [options]="getCountryConstant.countryCode" formControlName="countryCode" optionLabel="countryCode" (onChange)="updatePhoneNumber($event)">
                    </p-dropdown>
                    <label>Country Code
                      <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </label>
                  </span>
                </div>

                <!-- Phone Number Section -->
                <div class="col-5 rule-type-textbox sdps-p-around_medium sdps-m-left_large"
                     [ngClass]="{'invalidField': createUserForm.get('phoneNumber').value && createUserForm.get('phoneNumber').invalid || showErrorMsgPhone, 'disabled-grey': (myaccessModeEnabled && createUserForm.get('userType').value === 'Internal')}">
                  <span class="p-float-label phonenumber" *ngIf="selectedCountryCode === '1'">
                    <p-inputMask mask="999-999-9999" [autoClear]="false" #phoneNumber formControlName="phoneNumber"
                                 class="input-group-field"
                                 placeholder="___-___-____" (onInput)="isInputMaskFilled($event)" (focusout)="isInputMaskFilled($event)"
                                 [unmask]="true"></p-inputMask>

                    <label [class.mobile-number-label]="myaccessModeEnabled && createUserForm.get('userType').value === 'Internal'">
                      {{getUsersConstant.manageUserPage.phoneNumber}}
                      <span class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix" *ngIf="(!myaccessModeEnabled || createUserForm.get('userType').value === 'External') && createUserForm.get('userType').value !== 'Internal'"></span>
                    </label>
                  </span>

                  <span class="p-float-label" *ngIf="selectedCountryCode !== '1'">
                    <input type="text" formControlName="phoneNumber" #phoneNumber class="input-group-field" maxlength="15"
                           emsNumberPatterncheck (onInput)="isInputMaskFilled($event)" (focusout)="isInputMaskFilled($event)"  pInputText />

                    <label [class.mobile-number-label]="myaccessModeEnabled && createUserForm.get('userType').value === 'Internal'">
                      {{getUsersConstant.manageUserPage.phoneNumberNonUS}}
                      <span class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix" *ngIf="(!myaccessModeEnabled || createUserForm.get('userType').value === 'External') && createUserForm.get('userType').value !== 'Internal'"></span>
                    </label>
                  </span>
                </div>
              </div>

              <div class="sdps-grid-fluid">
                <!-- Email-id Section -->
                <div class="col-5 rule-type-textbox sdps-p-around_medium"
                     [ngClass]="{'invalidField': createUserForm.get('emailAddress').value && isEmailIdInvalid || showErrorMsgEmail, 'disabled-grey': (myaccessModeEnabled && createUserForm.get('userType').value === 'Internal')}">
                  <span class="p-float-label">
                    <input type="email" id="emailAddress" formControlName="emailAddress" maxlength="50" emsCharacterLimit [charMaxLimit]="getCommonConstant.charLimitValues.fiftyMaxLimit"
                    (emsCharacterLimitCheck)="showCharLimit = $event" 
                           pInputText>
                    <label>
                      {{getUsersConstant.manageUserPage.emailAddress}}
                      <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </label>
                  </span>
                  
                </div>
               



                <div *ngIf="sharedService.isInternalApp() && userService.editMode && !isUserTypeInt && user.status === getUsersConstant.reports.RulesDetails.active" class="col-5 rule-type-textbox sdps-p-around_medium sdps-m-left_large"
                    [ngClass]="{'invalidField': ((createUserForm.get('userName').value) && (createUserForm.get('userName').invalid)), 'disabled-grey': ((myaccessModeEnabled && createUserForm.get('userType').value === 'External') || isExternalUserDeActive)}">
                    <span class="p-float-label">
                      <input pInputText type="text" id="userName" formControlName="userName">
                      <label>
                        {{getUsersConstant.manageUserPage.userName}}
                      </label>
                    </span>
                </div>

         

                <!-- Schwab Section -->
                <div *ngIf="isUserTypeInt" class="col-5 rule-type-textbox sdps-p-around_medium sdps-m-left_large"
                     [ngClass]="{'invalidField': ((createUserForm.get('opaqueId').value && isOpaqueIdInvalid)), 'disabled-grey': ((myaccessModeEnabled && createUserForm.get('userType').value === 'Internal') || isExternalUserDeActive)}">
                  <span class="p-float-label">
                    <input type="text" id="opaqueId" formControlName="opaqueId" (focusout)="validateOpaqueId($event)" pInputText>
                    <label>
                      {{getUsersConstant.manageUserPage.opaqueId}}
                      <span *ngIf="createUserForm.get('userType').value !== 'Internal'" class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </label>
                  </span>
                </div>
              </div>
              <div class="sdps-grid-fluid charCls"
              *ngIf="createUserForm.get('emailAddress').value.length >= showCharLimit">
           <div class="col-5">
             <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
                *ngIf="createUserForm.get('emailAddress').value.length >= showCharLimit">
               {{getEMTRConstant.rulesConstant.characterLimit}}
               <span class="character-limit">{{createUserForm.get('emailAddress').value.length}}/{{getCommonConstant.charLimitValues.fiftyMaxLimit}}</span>
             </p>
           </div>
           </div>
              <!-- Select Bu or Client title -->
              <p *ngIf="isUserTypeInt" class="sdps-p-top_small">
                <strong [innerHTML]="getUsersConstant.manageUserPage.selectBUOrAssignClientToUser"></strong>
                <span class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
              </p>

              <!-- BU and Client selection Section -->
              <div class="sdps-grid-fluid">
                <!-- BU -->
                <div class="col-3 business-unit-border-right sdps-m-right_x-large" *ngIf="isUserTypeInt">
                  <p-radioButton class="emtr-radio ems-inline" name="businessUnitOrAssignClient"
                                 formControlName="businessUnitOrAssignClient" id="businessUnitCheckbox" [title]="(userPolicy.data.userPolicy.buPolicy === null) ||
                                 (user && (user.businessUnit && user.businessUnit.length === 3 && (userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length < 3)) || disableBURadioBtn) ||
                                 ((user && user.businessUnit && user.businessUnit.indexOf('RBS') !== -1 && noUAEntForRBS) || (user && user.businessUnit && user.businessUnit.indexOf('DBS') !== -1 &&noUAEntForDBS) || (user && user.businessUnit && user.businessUnit.indexOf('SPS') !== -1 && noUAEntForSPS)) ? 'Unauthorized' : ''"
                                 value="{{getUsersConstant.manageUserPage.businessUnits}}"
                                 [disabled]="(userPolicy.data.userPolicy.buPolicy === null) ||
                                 (user && (user.businessUnit && user.businessUnit.length === 3 && userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length < 3) || disableBURadioBtn) ||
                                 ((user && user.businessUnit && user.businessUnit.indexOf('RBS') !== -1 && noUAEntForRBS) || (user && user.businessUnit && user.businessUnit.indexOf('DBS') !== -1 &&noUAEntForDBS) || (user && user.businessUnit && user.businessUnit.indexOf('SPS') !== -1 && noUAEntForSPS))"
                                 label="{{getUsersConstant.manageUserPage.businessUnits}}"></p-radioButton>

                  <div class="sdps-m-vertical_small sdps-m-left_large"
                       *ngFor="let unit of businessUnits; let i = index">
                    <label [ngClass]="unit.disabled ? '' : 'cursor'">
                      <p-checkbox class="emtr-checkbox" formControlName="businessUnit" [value]="unit.name"
                                  id="units" (click)="unit.disabled || createUserForm.get('businessUnitOrAssignClient').value !== getUsersConstant.manageUserPage.businessUnits ? $event.preventDefault() : addBusinessUnit(unit.name)" [title]="((unit.name === 'DBS' && disableDBS) || (unit.name === 'SPS' && disableSPS) || (unit.name === 'RBS' && disableRBS)) ? 'Unauthorized' : ''"
                                  [ngClass]="[unit.disabled ? (createUserForm.get('businessUnit').value && createUserForm.get('businessUnit').value.indexOf(unit.name) !== -1  ? 'check-disabled':  'hide-checkbox-tick') : '', createUserForm.get('businessUnitOrAssignClient').value !== getUsersConstant.manageUserPage.businessUnits ? 'check-disabled' : '']"></p-checkbox>
                      <span class="sdps-m-left_small" [innerHTML]="unit.name"></span>
                    </label>
                  </div>
                </div>

                <!-- Client -->
                <div class="sdps-m-left_xx-small sdps-m-bottom_small">
                  <p-radioButton *ngIf="isUserTypeInt" class="emtr-radio ems-inline" id="assignClientCheckbox"
                                 name="businessUnitOrAssignClient" formControlName="businessUnitOrAssignClient" [title]="(disableSPS && disableDBS && disableRBS) || ((user && user.businessUnit && user.businessUnit.indexOf('RBS') !== -1 && noUAEntForRBS) || (user && user.businessUnit && user.businessUnit.indexOf('DBS') !== -1 && noUAEntForDBS) || (user && user.businessUnit && user.businessUnit.indexOf('SPS') !== -1 && noUAEntForSPS)) ||
                                 (user && (user.businessUnits && user.businessUnits.length > 1) && (userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length === 0)) ||
                                 (user && (user.businessUnits && user.businessUnits.length === 3 && userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length !== 3) || disableAssignClientRadioBtn) ? 'Unauthorized' : ''"
                                 value="{{getUsersConstant.manageUserPage.assignClientToUser}}"
                                 [disabled]="(disableSPS && disableDBS && disableRBS) || ((user && user.businessUnit && user.businessUnit.indexOf('RBS') !== -1 && noUAEntForRBS) || (user && user.businessUnit && user.businessUnit.indexOf('DBS') !== -1 && noUAEntForDBS) || (user && user.businessUnit && user.businessUnit.indexOf('SPS') !== -1 && noUAEntForSPS)) ||
                                 (user && (user.businessUnits && user.businessUnits.length > 1) && userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length === 0) ||
                                 (user && (user.businessUnits && user.businessUnits.length === 3 && userPolicy.data.userPolicy.buPolicy && userPolicy.data.userPolicy.buPolicy.length !== 3) || disableAssignClientRadioBtn)"
                                 label="{{getUsersConstant.manageUserPage.assignClientToUser}}"></p-radioButton>

                  <ng-container *ngIf="!isExternalUserDeActive">
                    <p *ngIf="!isUserTypeInt && clientRecords.length > 0">
                      <strong [innerHTML]="getUsersConstant.manageUserPage.assignClientToUser"></strong>
                      <span class="sdps-text-market-red" [innerHTML]="getCommonConstant.asterix"></span>
                    </p>
                    <div *ngIf="clientRecords.length > 0" class="sdps-m-top_small"
                         [ngClass]="{'sdps-m-left_large': isUserTypeInt}">
                      <div class="col-5 timePickerForm clientNameDropdown">
                        <p-dropdown #dropdown [emptyFilterMessage]="emptyDisplayMessage" class="sdps-p-top_small editableTimepicker client-name-dropdown"
                                    [options]="clientRecords" placeholder="Client Name" optionLabel="k4ClientName"
                                    filter="true" formControlName="clientNameDropdown" dataKey="clientId" (onHide)="resetFilterValue()"
                                    (onChange)="getClientDetails($event.value);" (onShow)= "onDropdownItemShow()" (input)="searchedText($event)"
                                     resetFilterOnHide="true" [virtualScroll]="true" itemSize="15">
                          <ng-template let-clientRecords pTemplate="item">
                            <div class="ui-helper-clearfix display-image">
                              <div class="image-block"
                                   [ngClass]="{'dbs': clientRecords.businessUnit === 'DBS', 'rbs': clientRecords.businessUnit === 'RBS', 'sps': clientRecords.businessUnit === 'SPS'}">
                                <span>{{clientRecords.businessUnit}}</span>
                              </div>
                              <div class="display-client-text">
                                <span>{{clientRecords.k4ClientName}}</span>
                              </div>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <!-- Selected business unit table section -->
              <div class="sdps-grid-fluid display-image" *ngIf="selectedBusinessUnit.length">
                <div class="col-11 sdps-p-around_none sdps-m-left_xx-small" formGroupName="userRoles">
                  <p-table class="common-data-table authorized-agent-table create-user-table sdps-p-around_none"
                           [value]="selectedBusinessUnit" selectionMode="checkbox" [(selection)]="selectedBusinessUnit">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th class="table-checkbox-selection"></th>
                        <th class="table-align" [innerHTML]="getUsersConstant.manageUserPage.businessUnitName"></th>
                        <th class="table-business-unit-selection"
                            [innerHTML]="getUsersConstant.manageUserPage.bu"></th>
                        <th [innerHTML]="getUsersConstant.manageUserPage.rolesLabel" class="table-align"></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sBu let-rowIndex="rowIndex">
                      <tr>
                        <td class="table-checkbox-selection">
                          <p-tableCheckbox class="emtr-checkbox" [(ngModel)]="selectedBusinessUnit[rowIndex]"
                            [value]="selectedBusinessUnit[rowIndex]" [disabled]="selectedBusinessUnit?.length > 0" binary="true"
                            [ngModelOptions]="{standalone: true}" ngDefaultControl></p-tableCheckbox>
                        </td>
                        <td class="word-break table-align" [innerHTML]="sBu"></td>
                        <td class="table-business-unit-selection">
                          <div class="image-block"
                               [ngClass]="{'dbs': sBu === 'DBS', 'rbs': sBu === 'RBS', 'sps': sBu === 'SPS'}">
                            <span [innerHTML]="sBu"></span>
                          </div>
                        </td>
                        <td class="word-break table-align" [formGroupName]="sBu">
                          <div class="col-1 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size">
                            <p-multiSelect appendTo="body" formControlName='roles' [options]="rolesDropdownData[sBu]" emptyMessage="No Roles available with current permissions."
                                           [disabled]="sBu === 'DBS' ? disableDBS  : sBu === 'SPS' ? this.disableSPS : this.disableRBS"
                                           [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true"
                                           [showToggleAll]="sBu === 'RBS' ? displayAllCheckbox : true"
                                           [maxSelectedLabels]="1" [showHeader]="true" [filterPlaceHolder]="sBu === 'RBS' && !displayAllCheckbox ? '' : 'All'">
                            </p-multiSelect>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>

              <!-- Selected clients table section -->
              <div class="sdps-grid-fluid display-image" *ngIf="selectedClient.length > 0">
                <div class="col-11 sdps-p-around_none sdps-m-left_xx-small" formGroupName="userRoles">
                  <p-table class="common-data-table authorized-agent-table create-user-table sdps-p-around_none"
                           [value]="selectedClient">
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th class="table-checkbox-selection"></th>
                        <th class="custid-align client-table-align" [innerHTML]="getUsersConstant.manageClients.clientDetailsPage.clientName"></th>
                        <th class="table-business-unit-selection"
                            [innerHTML]="getUsersConstant.manageUserPage.bu"></th>
                        <th class="custid-align client-table-align" [innerHTML]="getUsersConstant.manageUserPage.customerID"></th>
                        <th [innerHTML]="getUsersConstant.manageUserPage.rolesLabel" class="client-table-align"></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-selectedClient let-rowIndex="rowIndex">
                      <tr>
                        <td class="table-checkbox-selection">
                          <p-checkbox class="emtr-checkbox" [(ngModel)]="selectedClient.clientNameSelected" [disabled]="selectedClient.isClientDisabled || isExternalUserDeActive"
                                      binary="true"
                                      [ngModelOptions]="{standalone: true}"
                                      (ngModelChange)="assignClientValues(selectedClient,selectedClient.custId,selectedClient.clientId)" ngDefaultControl></p-checkbox>
                        </td>
                        <td class="word-break custid-align client-table-align" [innerHTML]="selectedClient.k4ClientName"></td>
                        <td class="table-business-unit-selection">
                          <div class="image-block"
                               [ngClass]="{'dbs': selectedClient.businessUnit === 'DBS', 'rbs': selectedClient.businessUnit === 'RBS', 'sps': selectedClient.businessUnit === 'SPS'}">
                            <span [innerHTML]="selectedClient.businessUnit"></span>
                          </div>
                        </td>
                        <td class="word-break custid-align client-table-align" [innerHTML]="selectedClient.custId"></td>
                        <td class="word-break client-table-align" [formGroupName]="selectedClient.custId">
                          <div class="col-1 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size">
                            <p-multiSelect appendTo="body" formControlName="roles" [filter]="true" [defaultLabel]="' '"
                                           [options]="rolesDropdownData && rolesDropdownData[rowIndex]" emptyMessage="No Roles available with current permissions."
                                           [displaySelectedLabel]="true"  [maxSelectedLabels]="1" [showHeader]="true"
                                           [showToggleAll]="selectedClient.businessUnit === 'RBS' ? displayAllCheckbox : true"
                                           [filterPlaceHolder]="selectedClient.businessUnit === 'RBS' && !displayAllCheckbox? '' : 'All'">
                            </p-multiSelect>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side section - Status, Created by, created date... -->
        <div class="step-border-box col-4 sdps-m-left_medium" *ngIf="userService.editMode">
          <div class="sdps-grid-container sdps-wrap">
            <div class="sdps-row sdps-p-top_medium">
              <div class="col-6">
                <strong class="font-size" [innerHTML]="getUsersConstant.manageUserPage.status"></strong>
              </div>
              <div class="col-6" *ngIf="user && user.status">
                <span class="status" ngClass="{{user.status | lowercase}}">
                  <span class="sdps-m-left_large" [innerHTML]="user.status | titlecase"></span>
                </span>
              </div>
            </div>

            <div class="sdps-row sdps-p-top_medium">
              <div class="col-6">
                <strong class="font-size" [innerHTML]="getUsersConstant.manageUserPage.statusField.createdBy"></strong>
              </div>
              <div class="col-6 word-break" *ngIf="user && user.crtUsrId"
                   [innerHTML]="user.crtUsrId">
              </div>
            </div>

            <div class="sdps-row sdps-p-top_medium">
              <div class="col-6">
                <strong class="font-size"
                        [innerHTML]="getUsersConstant.manageUserPage.statusField.createdDate"></strong>
              </div>
              <div class="col-6" *ngIf="user && user.crtTs"
                   [innerHTML]="user.crtTs | date: 'MM/dd/yyyy'">
              </div>
            </div>

            <div class="sdps-row sdps-p-top_medium" *ngIf="!isUserTypeInt">
              <div class="col-6">
                <strong class="font-size" [innerHTML]="getUsersConstant.manageUserPage.statusField.inviteDate"></strong>
              </div>
              <div class="col-6" *ngIf="user && user.inviteInfo"
                   [innerHTML]="user.inviteInfo.sentDate | date: 'MM/dd/yyyy'">
              </div>
            </div>

            <div class="sdps-row sdps-p-top_medium">
              <div class="col-6">
                <strong class="font-size" [innerHTML]="getUsersConstant.manageUserPage.statusField.updatedBy"></strong>
              </div>
              <div class="col-6 word-break" *ngIf="user && user.updUsrId"
                   [innerHTML]="user.updUsrId">
              </div>
            </div>

            <div class="sdps-row sdps-p-top_medium">
              <div class="col-6">
                <strong class="font-size"
                        [innerHTML]="getUsersConstant.manageUserPage.statusField.updatedDate"></strong>
              </div>
              <div class="col-6" *ngIf="user && user.updTs"
                   [innerHTML]="user.updTs | date: 'MM/dd/yyyy'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Action buttons - Cancel, Deactivate.... -->
  <div class="step-action-buttons sdps-p-top_medium">
    <div class="step-action-right sdps-pull-right">
      <!-- Cancel -->
      <button type="button" (click)="showMangeUser()" id="cancel" [innerHTML]="getUsersConstant.manageUserPage.cancel"
              class="buttons"></button>
      <!-- Deactivate -->
      <button type="button" *ngIf="(user && ((!myaccessModeEnabled && user.userType ==='Internal') || user.userType ==='External') && userService.editMode && user.status === 'Active')"
              (click)="deactivateManagerUser()" id="deactivate" [innerHTML]="getUsersConstant.deactivate.deactivate"
              class="buttons button-save"></button>
      <div class="sdps-pull-right" *ngIf="!userService.editMode || (user && user.inviteInfo)">

        <button  type="button" id="sendInvite" (click)="saveUserDetails(isUserTypeInt ? '' : this.manageUserService.editMode ? 'sendModify' : 'sendInvite')"
                [disabled]="!isPhoneValid || isEmailIdInvalid || showErrorMsgEmail || showErrorMsgPhone || !createUserForm.valid || (isUserTypeInt && isOpaqueIdInvalid) || clientAccess || (clientRecords.length > 0 &&((isUserTypeInt && (selectedBusinessUnit.length === 0 && selectedCustId.length<0 && selectedClientWithNoAccess.length<1)  || (!selectedBusinessUnit.length > 0 && selectedCustId.length === 0 && selectedClientWithNoAccess.length<1)) || (!isUserTypeInt && selectedCustId.length === 0 && selectedClientWithNoAccess.length<1)))"
                class="buttons button-save ng-star-inserted"
                [ngClass]="user && user.userType === 'Internal' && user.status === 'Pending' ? 'activate-btn': ''">
          {{
          user && user.userType === 'Internal' && user.status === 'Pending' ? 'Activate'
            : isUserTypeInt || (user && user.inviteInfo && user.status !== 'Active') || (!isUserTypeInt && user && user.status === 'Active') ? getUsersConstant.manageUserPage.save
            : getUsersConstant.manageUserPage.sendInvite
          }}
        </button>

        <!-- Send Invite and Add More -->
        <button type="button" id="sendInvite&Addmore" (click)="saveUserDetails('add')"
                [disabled]="!isPhoneValid || !createUserForm.valid || selectedClientId.length === 0 || clientAccess"
                class="buttons button-save ng-star-inserted"
                [innerHTML]="getUsersConstant.manageUserPage.sendInviteAddMore"
                *ngIf="!userService.editMode && !isUserTypeInt"></button>

        <!-- Resend Invite -->
        <button type="button" id="resendInvite" (click)="saveUserDetails('resend')"
                [disabled]="!isPhoneValid || isEmailIdInvalid || !createUserForm.valid || showErrorMsgEmail || showErrorMsgPhone"
                class="buttons button-save ng-star-inserted"
                *ngIf="userService.editMode && user && !isUserTypeInt && user.status !== 'Active'"
                [innerHTML]="getUsersConstant.manageUserPage.resendInvite"></button>
      </div>

    </div>

    <p class="sdps-clearfix">&nbsp;</p>
  </div>

</form>
