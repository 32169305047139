export interface EmpGrpAccTableHead {
  field?: string;
  header?: string;
}

export interface ManageEmpOrParticipantSecurityTableHeader {
  DBS?: EmpGrpAccTableHead[];
  SPS?: EmpGrpAccTableHead[];
  RBS?: EmpGrpAccTableHead[];
}


export const manageEmployeeSecurityTableHeader: EmpGrpAccTableHead[] = [
  { field: 'empId', header: 'Employee ID' },
  { field: 'subAcctNum', header: 'Account Number' },
  { field: 'firstName', header: 'First Name' },
  { field: 'lastName', header: 'Last Name' },
  { field: 'acctHolderFn', header: 'Account Holder <br> First Name' },
  { field: 'acctHolderLn', header: 'Account Holder <br> Last Name' },
];

export const empOrParticipantSecurityTableHeader: ManageEmpOrParticipantSecurityTableHeader = {
  DBS: [
    { field: 'empId', header: 'Employee ID' },
    { field: 'subAcctNum', header: 'Account Number' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'acctHolderFN', header: 'Account Holder <br> First Name' },
    { field: 'acctHolderLN', header: 'Account Holder <br> Last Name' },
  ],
  SPS: [
    { field: 'empId', header: 'Employee ID' },
    { field: 'subAcctNum', header: 'Account Number' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'acctHolderFN', header: 'Account Holder <br> First Name' },
    { field: 'acctHolderLN', header: 'Account Holder <br> Last Name' },
  ],

  RBS: [
    { field: 'subAcctNum', header: 'Account Number' },
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'acctHolderFN', header: 'Account Holder <br> First Name' },
    { field: 'acctHolderLN', header: 'Account Holder <br> Last Name' },
  ]
};

