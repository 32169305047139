export const commonConstants = {
  http_status: {
    code_400: 400,
    code_200: 200
  },
  date_format: {
    mm_dd_yy: 'MM-DD-YYYY'
  },
  empty_str: '',
  sorting: {
    sortText: 'Activate to sort',
    ascText: 'Activate to sort in ascending order',
    descText: 'Activate to sort in descending order',
  },
  asterix: '*',
  calendarYear: '2000:2030',
  rulesReportRuleType : {
  override: 'Override',
  restriction: 'Restriction',
  watchList: 'Watchlist'
  },
  characterLimit: 'Char. Limit:',
  charLimitValues: {
    thousandMaxLimit: 1000,
    threeHundredMaxLimit: 300,
    hundredMaxLimit: 100,
    twoHundredMaxLimit: 200,
    thirtyFiveMaxLimit: 35,
    thirtyMaxLimit: 30,
    twentyMaxLimit: 20,
    fiftyMaxLimit: 50,
    tenPercent: 10
  },
  delay: 5000,
  errorMessage: 'Internal Error Occurred.  Please try again.'
  };
